import React from "react"

import style from "./index.module.css"
import Footer from "../footer"

export interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FunctionComponent<LayoutProps> = props => {
  if (typeof window !== "undefined") {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  return (
    <div className={style.layout}>
      {props.children}
      <Footer></Footer>
    </div>
  )
}

export default Layout
