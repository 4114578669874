import * as React from "react"
import classNames from "classnames"
import style from "./index.module.css"
import andvic from "./andvic.svg"
import instagram from "./instagram.svg"
import { Link } from "gatsby"

interface IFooterProps {}

const Footer: React.FunctionComponent<IFooterProps> = props => {
  return (
    <footer className={style.footer}>
      <div className={style.logoWrap}>
        <img className={style.logo} src={andvic} />
      </div>
      <div>
        {/* <p className={style.blurb}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p> */}
      </div>

      <div>
        <ul className={style.footerList}>
          <li>
            <Link to="/about" className={style.footerLink}>
              About
            </Link>
          </li>
          <li>
            <Link to="/contact" className={style.footerLink}>
              Contact
            </Link>
          </li>
          <li>
            <a
              href="https://www.instagram.com/andvicprojects/?hl=en"
              target="_blank"
              className={classNames(style.footerLink, style.instagramLink)}
            >
              <span>Instagram</span>{" "}
              <img className={style.instagram} src={instagram} />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
